import React from "react";
import { generateConfigData, generateInfoCard, processLink, generateLink } from "Common/utility/infoCard"
import { generateCardsJson } from "Common/utility/serialize";
import PortalError from "../portalError/portalError";
import { MAX_ELLIPSIS_LENGTH } from "../../../js/common/base/constants";
import axios from "axios";


export default class PortalSites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
    }

    componentDidMount() {
        const { config } = this.props;
        const jsURL = config.querySelector('siteLink')?.textContent.trim();
        if(!jsURL) {
            return false;
        }
        var req = axios.get(jsURL)
            .then( (response) => {
               this.setState({ data: response.data.items[0].items })
     
            })
            .catch( (error) => {
                console.log(error);
        })
       
    }

    render() {
            const { config, location, type } = this.props;
    
            if (!config) {
                return <span>Loading</span>
            }
            const { data } = this.state;
            
            return (
                
                <> 
                    <div className="row header_data">
                        <div className="col-4">Sites</div>
                        <div className="col-8">API Groups</div>
                    </div>{data.map((i,indx) =>
                        <div key={indx} className="row section-data py-3">
                            <div className="col-4 d-flex">
                                <span>{i.name}</span>
                                <span className="ml-2">
                                        <hbr-tooltip content={i.description} placement="top" class="atm-tooltip--small">
                                            <i class="atm-icon info-circle-filled"></i>
                                        </hbr-tooltip>
                                 </span>
                                </div>
                            <div className="col-8">{i.items.map((x,idx) =>
                                ( <>
                                <div key={idx} className="d-flex">
    
                                    <span>{x.name}</span> 
                                    <span className="ml-2">
                                        <hbr-tooltip content={x.description} placement="top" class="atm-tooltip--small">
                                            <i class="atm-icon info-circle-filled"></i>
                                        </hbr-tooltip>
                                    </span>
                                </div>
                                </>
                                  
                                  )  )}</div>
                        </div> 
                
    
                )}
                
                </>
            )
    }
}