import React from "react"
import ReactDOM from "react-dom"

import axios from "axios";

import Router from "./Router";

import {isElement} from "../utility/utils.js";

const createReactClass = require('create-react-class');

const web_json_name = "web.json";

const ComponentClass = createReactClass({
    render() {
        return <div>
            <div ref="component"></div>
        </div>;
    }
});


export default class RoutableContainer {


    /**
     * @property container
     */
    set container(value) {

        var _container = value;

        if (!isElement(_container)) {
            _container = document.querySelector(value)
        }

        if (!isElement(_container)) {
            throw "Container value should be a doc element or queryString";
        }

        if (this.component) {
            // todo, for move instance to another container
            ReactDOM.unmountComponentAtNode(this._contaner);

        } else {
            const el = React.createElement(this.componentClass, {
                "router": this.router
            });
            const wrapper = ReactDOM.render(el, _container);
            this.wrapper = wrapper;
            if (wrapper.refs["component"]) {
                this.component = wrapper.refs["component"].getWrappedInstance ? wrapper.refs["component"].getWrappedInstance() : wrapper.refs["component"];
                this.component.instance = this; /// ioc;
            }


            //register router to container
            this.router.delegateContainer = _container;
            this.router.onChange(function (event, router) {
                this._navigationDidChange()
            }.bind(this));

            this.componentDidMount(this.component, wrapper);
        }

        this._contaner = _container;
    }

    get container() {
        return this._contaner
    }


    /**
     * @property location
     */

    set location(value) {

        if (this._location != value) {

            if (this._location && this._location.indexOf(value) != -1) {
                return; // host property will change _location, so check is new value is part of old value
            }

            if (this._location) {
                this.clear();
            }

            if (this.host) {
                value = this.host + value
            }

            this._location = value;

            // auto load web.json
            if (this.autoLoadConfig) {
                this.configURL = value + web_json_name;
            }
        }
    }

    get location() {
        return this._location;
    }


    /**
     * @property configURL
     */
    set configURL(value) {
        if (this._configURL != value) {


            if (this._configURL) {
                this.clear();
            }

            if (this.host && value.indexOf(this.host) == -1) {
                value = this.host + value
            }

            this._configURL = value;
            this._fetch_config_file(value);
        }

    }

    get configURL() {
        return this._configURL;
    }

    set isAuthenticated(value) {
        this._isAuthenticated = value;
        if (!this._isAuthenticated) {
            window.sessionStorage.removeItem("isAuthenticated");
        }
        else {
            window.sessionStorage.setItem("isAuthenticated", value);
        }
            
    }

    get isAuthenticated() {
        return this._isAuthenticated;
    }


    set accessLevel(value) {
        this._accessLevel = JSON.parse(sessionStorage.getItem('actAsPartner')) ? 2: value;
        if (!this._accessLevel) {
            window.sessionStorage.removeItem("accessLevel");
        }
        else {
            window.sessionStorage.setItem("accessLevel", this._accessLevel);
        }     
    }

    get accessLevel() {
        return this._accessLevel;
    }

    set url(value) {
        this.router.navigateTo(value);
    }

    get url() {
        return this.router.queryString
    }


    /**
     * @constructor
     */
    constructor() {
        /**
         * @property component
         * @type {Component}
         */
        this.component = null;

        /**
         * @property componentClass
         * @type {ReactComponent}
         */

        this.componentClass = ComponentClass;

        /**
         * @property router
         * @type {Router}
         */
        this.router = new Router();
        /**
         * @property configData
         * @type {Object}
         */
        this.configData = null;

        /**
         * @property defaultURI
         * @type {String}
         */
        this.defaultURI = null;
        /**
         * @property host
         * @type {String}
         */
        this.host = null;
        this._changeFN = null;
        this._loadFN = null;
        this.autoLoadConfig = true;
    }

    onChange(value) {
        this._changeFN = value;
    }

    onLoad(value) {
        this._loadFN = value;
    }


    componentDidMount(component) {
    }

    configDataDidLoad(data) {

    }

    configDataGetError(error) {

    }


    navigationDidChange(url) {

    }


    _navigationDidChange() {
        //fire navigation change
        let fire = true;
        if (this._changeFN) {
            fire = this._changeFN.call(null, this.router.queryString, this.router)
        }

        try {
            const _event = new CustomEvent("change", {
                detail: {
                    data: this.router.queryString
                },
                bubbles: true,
                cancelable: true
            });
            const cancelled = this._contaner.dispatchEvent(_event);

            if (cancelled === false) {
                return false;
            }
        } catch (e) {

        }

        if (fire !== false) {
            this.navigationDidChange(this.router.queryString);
        }

        try {
            const _event = new CustomEvent("select", {
                detail: {
                    data: this.router.queryString
                },
                bubbles: true,
                cancelable: true
            });
            const cancelled = this._contaner.dispatchEvent(_event);

            if (cancelled === false) {
                return false;
            }
        } catch (e) {

        }


    }


    navigateTo(value) {
        this.url = value;
    }


    clear() {
        //
        this.defaultURI = null;
        this.configData = null;
        this._location = null;
        this._configURL = null;
        this._changeFN = null;
        this._loadFN = null;
        this._isAuthenticated = false;
        this.router.clear();
    }

    unmount() {
        this.clear();
        ReactDOM.unmountComponentAtNode(this._contaner);
    }


    /**
     * @method
     * @param url
     * @private
     */
    _fetch_config_file(url) {
        //cancel ongoing ajax call
        if (this._config_source) {
            this._config_source.cancel()
        }
        var CancelToken = axios.CancelToken;
        var source = this._config_source = CancelToken.source();
        var self = this;
        var commitid = document.querySelector("[name=commitid]");
       // var id = ""//commitid ? commitid.getAttribute("content") : Math.random();
        var withCredentials = false;//!!url.match(/devnet.*.cisco.com/);

        const node = document.querySelector(`[pubhub-config="${url}"]`);
        if (node) {
            try {
                const data = JSON.parse(node.innerHTML);
                setTimeout(() => {
                    const _event = new CustomEvent("configDataLoaded", {
                        detail: {
                            data,
                        },
                        bubbles: true,
                        cancelable: true
                    });
                    const cancelled = self._contaner.dispatchEvent(_event);
                    if (cancelled === false) {
                        return false;
                    }
                    self.configData = data;
                    self.configDataDidLoad(data);
                    if (self._loadFN) {
                        self._loadFN.call(null, self.component)
                    }
        
                    const _init_event = new CustomEvent("init", {
                        detail: {
                            data: self
                        },
                        bubbles: true,
                        cancelable: true
                    });
                    self._contaner.dispatchEvent(_init_event);
                });
            } catch(e) {
                self.configDataGetError(e)
                
                const _event = new CustomEvent("configDataError", {
                    detail: {
                        url: url,
                        message: e.message,
                        stack: e.stack
                    },
                    bubbles: true,
                    cancelable: true
                });
                self._contaner.dispatchEvent(_event);
            }

            return;
        }
        var req = axios.get(url, {
            cancelToken: source.token,
            withCredentials: withCredentials
        })
            .then(function (response) {
                try {
                    if(response.data && response.data.items) {
                        let appRefIndx = response.data.items.findIndex(x=> x.url === "api-reference");
                        if(appRefIndx != -1) {
                            let appIndx = response.data.items[appRefIndx].items.findIndex(x => x.type === "doc_page");
                            if(appIndx !=-1) {
                                response.data.items[appRefIndx].items[appIndx].url = response.data.items[appRefIndx].items[appIndx].content;
                                response.data.items[appRefIndx].items[appIndx].content = "";
                            }
                        }
                    }
                    
                   /// response.data.meta.location = "https://it-developer-nprd-test.cisco.com/puball/"
                    if(response.data && response.data.config) {
                        if(response.data.config['apiId']) sessionStorage.setItem("apiId",response.data.config['apiId']);
                        if(response.data.config['assetId']) sessionStorage.setItem("isManaged", response.data.config['isManaged'] ?? true);
                    }
                    const _event = new CustomEvent("configDataLoaded", {
                        detail: {
                            data: response.data
                        },
                        bubbles: true,
                        cancelable: true
                    });
                    const cancelled = self._contaner.dispatchEvent(_event);

                    if (cancelled === false) {
                        return false;
                    }
                    self.configData = response.data;
                    self.configDataDidLoad(response.data);
                    if (self._loadFN) {
                        self._loadFN.call(null, self.component)
                    }

                    const _init_event = new CustomEvent("init", {
                        detail: {
                            data: self
                        },
                        bubbles: true,
                        cancelable: true
                    });
                    self._contaner.dispatchEvent(_init_event);


                } catch (e) {
                    console && console.log(e)
                }

            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    console && console.info("abort")
                } else {
                    self.configDataGetError(thrown)
                }
                
                try {
                    const _event = new CustomEvent("configDataError", {
                        detail: {
                            url: thrown.config.url,
                            status: thrown.response.status,
                            statusText: thrown.response.statusText
                        },
                        bubbles: true,
                        cancelable: true
                    });
                    self._contaner.dispatchEvent(_event);
                } catch(e) {
                    console.error(e)
                }
            });
    }


    on(eventName, eventHandler, useCapture = false) {
        if (this._contaner) {
            this._contaner.addEventListener(eventName, eventHandler, useCapture)
        }
    }

    off(eventName, eventHandler, useCapture = false) {
        if (this._contaner) {
            this._contaner.removeEventListener(eventName, eventHandler, useCapture)
        }
    }


}


