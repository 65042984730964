/**
 * Created by aaikepae on 11/7/16.
 */
import React from "react"

export default class extends React.Component {

  render() {

    const {selected, config} = this.props;

    if (!config.showPageNavigationBtn) {
      return <span/>
    }

    if (selected.item["config"] && typeof selected.item["config"]["showPageNavigationBtn"] != "undefined" && selected.item["config"]["showPageNavigationBtn"] == false) {
      return <span/>
    }


    var previousBtn, nextBtn;
    if (selected["data"]) {
      previousBtn = selected.item.previous ? <a onClick={(e) => {
        if (this.props.router.mode === 'history') {
          e.preventDefault();
          this.props.router.navigateTo(selected.item.previous);
        }
      }} href={"#!" + selected.item.previous} data-tag="navigation-item"
                                                data-url={selected.item.previous}>Previous</a> : null;

      if(selected.item.next) {
        nextBtn = <a onClick={(e) => {
          if (this.props.router.mode === 'history') {
            e.preventDefault();
            this.props.router.navigateTo(selected.item.next);
          }
        }} href={"#!" + selected.item.next} data-tag="navigation-item" data-url={selected.item.next}>Next</a>
      }  else {
        nextBtn = null
      }                                      
      // nextBtn = selected.item.next  ?
      //   : null;
    }

    return <div className="pageNavigationLinks_bottom">
      {previousBtn}
      {nextBtn}
    </div>
  }
}
