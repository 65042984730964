import wrap from "../basic/wrapper";
import FeatureSection from "./featureSection/FeatureSection";
import GettingStartedSection from "./gettingStartedSection/GettingStartedSection";
import Banner from "./heroBannerStatic/Banner";
import PortalBanner from "./portalBanner/Portalbanner"
import PortalCommunity from "./portalCommunity/PortalCommmunity"
import PortalCapabilities from "./portalCapabilities/portalCapabilities"
import PortalError from "./portalError/portalError"
import PortalGroupCard from "./portalGroupCard/portalGroupCard"
import SidebarContentViewer from "./sidebarContentViewer/SidebarContentViewer";
import WhatsNew from "./whatsNew/WhatsNew";
import PortalSites from "./portalSites/portalSites";

const widgets = {
    "blog": { loader: () => import(/* webpackChunkName: "blog" */  './blog/Blog') },
    "ciscoblog": { loader: () => import(/* webpackChunkName: "ciscoblog" */  './ciscoBlog/CiscoBlog') },
    "sandboxinfocard": { loader: () => import(/* webpackChunkName: "sandboxinfocard" */  './sandboxInfoCard/SandboxInfoCard') },
    "learninglablist": { loader: () => import(/* webpackChunkName: "learninglablist" */  './learningLabList/LearningLabList') },
    "learninglabtrack": { loader: () => import(/* webpackChunkName: "learninglabtrack" */  './learningLabTrack/LearningLabTrack') },
    "learninglabModule": { loader: () => import(/* webpackChunkName: "learninglabModule" */  './learningLabModule/LearningLabModule') },
    "learninglab": { loader: () => import(/* webpackChunkName: "learninglab" */  './learningLab/LearningLab') },
    "banner": Banner,
    "samplecodeblocks": { loader: () => import(/* webpackChunkName: "samplecodeblocks" */  './sampleCodeBlocks/SampleCodeBlocks') },
    "sandbox": { loader: () => import(/* webpackChunkName: "sandbox" */  './sandbox/Sandbox') },
    "support": { loader: () => import(/* webpackChunkName: "support" */  './support/Support') },
    "breadcrumb": { loader: () => import(/* webpackChunkName: "breadcrumb" */  './breadcrumb/Breadcrumb') },
    "creationCarousel": { loader: () => import(/* webpackChunkName: "creationCarousel" */  './creationCarousel/CreationCarousel') },
    "youtube": { loader: () => import(/* webpackChunkName: "youtube" */  './youtube/youtube') },
    "siteNotification": { loader: () => import(/* webpackChunkName: "siteNotification" */  './siteNotification/SiteNotification') },
    "carousel": { loader: () => import(/* webpackChunkName: "carousel" */  './carousel/Carousel') },
    "featureSection": FeatureSection,
    "youtubeVideo": { loader: () => import(/* webpackChunkName: "youtubeVideo" */  './youtubeVideo/YoutubeVideo') },
    "portalBanner": PortalBanner,
    "portalCommunity": PortalCommunity,
    "portalCapabilities": PortalCapabilities,
    "portalError":PortalError,
    "post": { loader: () => new Promise((resolve, reject) => {
        import(/* webpackChunkName: "post" */  './post/post').then((compoent) => {
            resolve({
                default: wrap(compoent.default),
            });
        }).catch(reject);
    })},

    "agenda": { loader: () => import(/* webpackChunkName: "agenda" */  './agenda') },
    "learningpath": { loader: () => import(/* webpackChunkName: "learningpath" */  './learningPath') },
    "speaker": { loader: () => import(/* webpackChunkName: "speaker" */  './Speaker') },

    "codeexchange": { loader: () => new Promise((resolve, reject) => {
        import(/* webpackChunkName: "codeexchange" */  './Exchange').then((compoent) => {
            resolve({
                default: wrap(compoent.default),
            });
        }).catch(reject);
    })},
    "exchange": { loader: () => new Promise((resolve, reject) => {
        import(/* webpackChunkName: "exchange" */  './Exchange').then((compoent) => {
            resolve({
                default: wrap(compoent.default),
            });
        }).catch(reject);
    })},
    "codeexchangecard": { loader: () => new Promise((resolve, reject) => {
        import(/* webpackChunkName: "codeexchangecard" */  './codeExchangeCard/CodeExchangeCard').then((compoent) => {
            resolve({
                default: wrap(compoent.default),
            });
        }).catch(reject);
    })},
    "sectionframe": { loader: () => import(/* webpackChunkName: "sectionframe" */  './sectionFrame') },
    "ecosystemexchangecard": { loader: () => import(/* webpackChunkName: "ecosystemexchangecard" */  './EcosystemExchangeCard') },
    "automationexchangecard": { loader: () => new Promise((resolve, reject) => {
        import(/* webpackChunkName: "automationexchangecard" */  './AutomationExchangeCard').then((compoent) => {
            resolve({
                default: wrap(compoent.default),
            });
        }).catch(reject);
    })},
    "drawing": { loader: () => import(/* webpackChunkName: "drawing" */  './drawing') },
    "raffle": { loader: () => import(/* webpackChunkName: "raffle" */  './raffle') },
    "extendedform": { loader: () => import(/* webpackChunkName: "extendedform" */  './ExtendedForm') },
    "GettingStartedSection": GettingStartedSection,
    "SidebarContentViewer": SidebarContentViewer,
    "portalGroupCard": PortalGroupCard,
    "whatsNew": WhatsNew,
    "portalSites": PortalSites
 };
 
 export default widgets
 
