import React, { useState, useEffect } from 'react';
import './_requestDetails.scss'

function RequestDetails(props) {
   const request = props.request;
    return (
        <>
        <div className="expanded-row d-flex  align-items-center">
          <div className="expand-row-section">
            <div>
              <p className="mb-2"><strong>Date of Request:</strong><br />{request.requestedDate.slice(0, 10)}</p>
              <p><strong>Date of Change:</strong><br /> {request.updatedDate.slice(0, 10)}</p>
            </div>
          </div>

          {request.slaTier &&
            <div className="expand-row-section">
              <hr className='vertical-line'></hr>
              <div>
                <p className="mb-2"><strong>API Instance:</strong><br />{request.apiInstance}</p>
                <p><strong>SLA Tier:</strong><br />{request.slaTier.name} <br></br>
                  <span className="sla_reqest">{`(${request.slaTier.requestNumber} request per ${request.slaTier.timePeriod} milli seconds)`}</span> </p>
              </div>
            </div>
          }
          <div className="expand-row-section">
            <hr className='vertical-line'></hr>
            <div>
              <p className="mb-2 api-detail"><strong>API Details:</strong><br />Version : <span className='api-detail-version'> {request.apiDetails.version}</span> <br />
                Site: {request.apiDetails.domainName} <br></br>
                Api Groups: {request.apiDetails.subdomainName ? request.apiDetails.subdomainName : '-'}
              </p>
            </div>
          </div>
          <div className='expand-row-section'>
            <hr className='vertical-line'></hr>
            <div className="">
              <p className="mb-2"><strong>Request Reason:</strong><br />{request.requestReason}</p>
              {request.status.toLowerCase() === 'denied' && (
                <p><strong>Denied Reason:</strong>{`(by ${request.deniedBy})`}<br />{request.deniedReason} </p>
              )}
            </div>
          </div>
        </div>
      </>
    )
}


export default RequestDetails;