/**
 * Created by aaikepae on 11/3/16.
 */
import React from "react"
import {connect} from "react-redux"
import {bindActionCreators} from 'redux';

import {getActAsPartnerAccess, loadJSFile} from "../../../common/utility/utils";

import {loadContent} from "../../actions/content";

import SearchList from "./SearchList"
import Static from "./contentType/Static"
import HTML from "./contentType/HTML"
import { shouldUseWebComponent, WebComponent } from "./contentType/Webcomponent"
import MyApps from "../MyApps/MyApps";
import Loader from "../../../common/base/Loader/Loader";
import ActAsPartnerAccessError from "../../../common/base/ActAsPartnerAccessError/ActAsPartnerAccessError";
import PortalError from "../../../../widgets/microsite/portalError/portalError";

// import Footer from "./Foorter"
// import {Code} from "./Code";

export class Content extends React.Component {
    componentWillMount() {
        const {selected} = this.props;
        console.log(selected)
        if (selected["item"] && !selected.data) {
            this.props.loadContent(selected.item);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { selected, config } = nextProps;
        //when select item, trigger content generation
        if (selected["item"] && !selected.data && selected["item"] !== this.props.selected["item"]) {
            this.props.loadContent(selected.item);
        }
        if (config.useNPS && config.useNPS !== this.props.config.useNPS) {
            loadJSFile(CONFIG.NPS_SDK).then(() => {
                if (window.NpsSDK) {
                    window.NpsSDK.loadForm(config.useNPS);
                }
            })
        }
    }

    render() {

        const {selected, config, items, search, router} = this.props;

       if (selected.itemURL && selected.itemURL === 'my_apps') {

       } else if (!items || items.length == 0) {
            console.log(selected)
            return <><Loader/></>
        } else if (search["keyword"]) {
            console.log(selected)
            return <div className="pubhub-doc-container pubhub-search-container">
                <section>
                    <SearchList router={router} />
                </section>
            </div>
        } else if (!selected["data"] || !selected.item) {
            
            return  <>
            <div className="pubhub-doc-container"></div>
            <Loader/>
            </>;
        }


        //bottom navigation
        let child, isIncludeWidget;
        const type = selected.item.type;



        if (type == "file") {
            window.open(config.location + selected.item.content, "_blank");
            child =
                <p>Please click <a href={config.location + selected.item.content} target="_blank">here</a> if this file
                    does not
                    open automatically!</p>;

        } else if (["html", "swagger", "raml"].indexOf(type) !== -1) {
            child = <HTML selected={selected} data={selected["data"]} config={config} {...this.props} ref="doc"/>;

        } else if (shouldUseWebComponent(type)) {
            child = <WebComponent selected={selected} data={selected["data"]} config={config} {...this.props}
                                  ref="doc"></WebComponent>
        } else if(selected.itemURL && selected.itemURL === 'my_apps') {
                if(getActAsPartnerAccess('my_apps')) {
                    // will change it once design is ready
                    child = <ActAsPartnerAccessError />
                } else {
                    child =  <>
                        <div className="column-doc column-doc-static">
                            <MyApps selected={selected} config ={config} items={items}/>
                        </div>
                    </>
                }
                isIncludeWidget = true
        }else {
            child = <Static key={selected.orphenLinkUrl || 'normal-static'} selected={selected} data={selected["data"]} config={config} {...this.props} ref="doc"
              onStartLoadOrphenLink={this.props.onStartLoadOrphenLink}
              onOrphenLinkGoback={this.props.onOrphenLinkGoback}/>;

            isIncludeWidget = true
        }
        // const footer = <Footer config={this.props.config} selected={this.props.selected}/>;
        const widgetID = isIncludeWidget ? "pubhub-widgets" : "";

        let codeLayoutClass = config.codeLayout;
        if ((codeLayoutClass == "two-columns" && config["_platform"] !== "lg") || (selected.codeSections && selected.codeSections.length == 0)) {
            codeLayoutClass = "one-column"
        }
        const widgetClass = ["doc-content", type, codeLayoutClass].join(" ");

        let userAccessLevel = window.sessionStorage.getItem("accessLevel") ?? 0;
        const selectedItemTitle = selected.item.url;
        const parentItem = items.find(item => 
            item.children?.some(child => child === selectedItemTitle)
            );

        const itemAccessLevel = parentItem?.accessLevel ?? 0;

        let originalHTML = `<portalError type="" height="height-80">
        <title>Access Restricted</title>
        <message>You do not have access to view this page.</message>
        <imageSrc width="300px" height="300px">restricted</imageSrc>
        </portalError>`
        const wrapper = document.createElement("div");
        wrapper.innerHTML = originalHTML;
        const errorConfig = wrapper;

        return (
            <div className={"pubhub-doc-container " + config.layout} ref="root">
                <section className={widgetClass} id={widgetID}>
                    {itemAccessLevel > userAccessLevel ? <PortalError config={errorConfig} /> : child }
                </section>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadContent: bindActionCreators(loadContent, dispatch)
    };
}

export default connect(null, mapDispatchToProps, null, {withRef: true})(Content);
